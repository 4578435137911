<template>
  <StepperDialog ref="import_dialog" title="Importing Website Products" max_width="65%" :steps=2>
    <template #confirm-action="{ options }" v-if="search.type !== 'all' && import_options.selected_products.length === 0">
      <ActionDialog ref="confirm_dialog" title="Confirm Import" color="warning">
        <template v-slot>
          <span class="warning--text" v-html="'Warning! Are you sure you want to continue?<br><br>You have not selected any products. No products will be imported if you continue.'"></span>
        </template>
      </ActionDialog>
      <v-btn class="rounded-lg" outlined color="warning" @click="$refs.confirm_dialog.showDialog().then(() => options.confirm(options.data)).catch(()=>{})">Confirm</v-btn>
    </template>

    <template #step_1_header>Products</template>
    <template #step_1_content>
      <!-- Search Type Radio Buttons -->
      <v-radio-group v-model="search.type" row :hide-details="search.type === 'all'" class="mt-0" :class="{ 'mb-2': search.type === 'all'}" mandatory>
        <v-radio label="All Products" value="all" class="mr-auto" :class="{ 'ml-auto': website.type === 'B2BWave' }"></v-radio>
        <v-radio v-if="website.type === 'Shopify'" label="Search Collections" value="collections" class="mx-auto"></v-radio>
        <v-radio v-if="website.type === 'Shopify'" label="Search Products" value="products" class="ml-auto"></v-radio>
      </v-radio-group>

      <!-- Search Text Field -->
      <v-expand-transition>
        <v-text-field
          v-if="search.type !== 'all'"
          id="search_field"
          v-model="search.string"
          :label="`Search Shopify ${search.type}`"
          :placeholder="`Search ${search.type} to select for import`"
          :hint="search_hint"
          persistent-hint
          clearable
          outlined
          dense
          class="rounded-lg"
          @keydown.enter.prevent="searchShopify(search)"
          @input="debounceSearchShopify(search)"
          @click:clear="searchShopify(search)"
        ></v-text-field>
      </v-expand-transition>

      <!-- Results Card -->
      <v-expand-transition>
        <v-card v-if="search.type !== 'all'" outlined class="rounded-lg" style="overflow: hidden;" :loading="search_loading" :disabled="search_loading">
          <v-card-text class="pa-0" style="max-height: 324px; overflow-y: auto;">
            <v-list two-line class="pa-0">
              <template v-for="(result, i) in search_results">
                <ProductListItem
                  v-if="result.__typename === 'Product'"
                  :product="result"
                  :selected_products="import_options.selected_products"
                  @selectProduct="selectProduct"
                  :key="`${i}_product`"
                ></ProductListItem>

                <CollectionListItem
                  v-if="result.__typename === 'Collection'"
                  :collection="result"
                  :selected_products="import_options.selected_products"
                  @selectCollection="selectCollection"
                  :key="`${i}_collection`"
                ></CollectionListItem>

                <v-divider :key="`${i}_divider`" v-if="i < search_results.length-1"/>
              </template>

              <!-- No Results List Item -->
              <v-list-item v-if="search_results.length === 0">
                <v-list-item-content>
                  <v-list-item-title>No Results 🔍</v-list-item-title>
                  <v-list-item-subtitle>There were no results found for this search.</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>

            </v-list>
          </v-card-text>

          <v-divider v-if="search.cursor !== null"/>
          <v-card-actions v-if="search.cursor !== null" class="pa-0">
            <v-btn @click="searchShopify(search, true)" block text class="rounded-t-0 rounded-b-lg">Load More</v-btn>
          </v-card-actions>
        </v-card>
      </v-expand-transition>
    </template>

    <template #step_2_header>Review</template>
    <template #step_2_content="{ options }">
      <v-card outlined class="rounded-lg">
        <v-card-title class="py-2">
          Selected Products <span v-if="search.type !== 'all'">&nbsp;({{ import_options.selected_products.length }})</span>
          <v-spacer/>
          <v-btn v-if="import_options.selected_products.length" @click="import_options.selected_products = []" class="rounded-lg">Remove All</v-btn>
        </v-card-title>
        <v-divider/>

        <v-card-text class="pa-0">
          <v-list two-line class="pa-0">
            <v-virtual-scroll
              :items="import_options.selected_products"
              :item-height="65"
              :bench="6"
              :max-height="import_options.selected_products.length === 5 ? 325 : 324"
            >
              <template v-slot:default="{ index, item }">
                <v-list-item :key="`${index}_product_selected`">
                  <v-list-item-content>
                    <v-list-item-title>{{ item.title }}</v-list-item-title>
                    <v-list-item-subtitle>Variants: {{ item.totalVariants }}</v-list-item-subtitle>
                  </v-list-item-content>

                  <v-list-item-action>
                    <v-btn @click="selectProduct(item)" class="rounded-lg">Remove</v-btn>
                  </v-list-item-action>
                </v-list-item>

                <v-divider :key="`${index}_divider`" v-if="index < import_options.selected_products.length-1"/>
              </template>
            </v-virtual-scroll>

            <!-- No Results List Item -->
            <v-list-item v-if="import_options.selected_products.length === 0">
              <v-list-item-content v-if="search.type === 'all'">
                <v-list-item-title>All Products Selected</v-list-item-title>
                <v-list-item-subtitle>All products from <b>{{ website.name }}</b> will be imported.</v-list-item-subtitle>
              </v-list-item-content>
              <v-list-item-content v-else>
                <v-list-item-title>No Products Selected</v-list-item-title>
                <v-list-item-subtitle>You have not selected any products.</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-card-text>

        <v-divider/>
        <v-card-actions class="pa-0">
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <div v-on="on" class="mx-auto">
              <v-checkbox v-model="options.data.import_duplicates" label="Import Duplicates" hide-details class="mt-1 mb-2"></v-checkbox>
              </div>
            </template>
            <span>If checked, all products, regardless of if it already exists will be imported.</span>
          </v-tooltip>
        </v-card-actions>
      </v-card>
    </template>
  </StepperDialog>
</template>

<script>
import _debounce from 'lodash/debounce';
import StepperDialog from '../../StepperDialog.vue';
import ActionDialog from '../../ActionDialog.vue';
import ProductListItem from './ProductListItem.vue';
import CollectionListItem from './CollectionListItem.vue';

export default {
  components: {
    StepperDialog,
    ActionDialog,
    ProductListItem,
    CollectionListItem,
  },
  props: {
    website: Object,
  },
  data() {
    return {
      search: {
        string: '',
        type: 'all',
        cursor: null,
      },
      search_loading: false,
      search_results: [],
      import_options: {
        import_type: 'All',
        selected_products: [],
        import_duplicates: false,
      }
    }
  },
  watch: {
    'search.type'(type) {
      if (type === 'all') {
        this.search_results = [];
        this.import_options.import_type = 'All';
        this.import_options.selected_products = [];
      } else {
        this.import_options.import_type = 'Selected';
        this.searchShopify(this.search);
      }
    }
  },
  computed: {
    search_hint() {
      switch(this.search.type) {
        case 'collections':
          return 'Hint: Available filters include collection title, type, and publishable/published status.';
        case 'products':
          return 'Hint: Available filters include product title, ID, vendor (brand), price, inventory, status, type, tags, barcode, and SKU.';
        default:
          return 'Select a valid search type';
      }
    }
  },
  methods: {
    resetData() {
      this.search = {
        string: '',
        type: 'all',
        cursor: null,
      };
      this.search_results = [];
      this.import_options.selected_products = [];
      this.import_options.import_duplicates = false;
    },
    show() {
      return this.$refs.import_dialog.showDialog(this.import_options)
        .then(data => {
          data = JSON.parse(JSON.stringify(data));
          // Map the ID's of the selected products to send to the backend
          data.selected_products = data.selected_products.map(x => x.id);
          this.$axios.post(`${process.env.VUE_APP_API_BASE_URL}/websites/${this.website.website_id}/import`, data)
            .then(() => {
              document.getElementById('notificationsBell').click();
            })
            .catch(err => {
              console.log(err);
            });
          this.resetData();
        })
        .catch(() => {
          this.resetData();
        });
    },
    selectProduct(product) {
      // If product is already selected, remove it
      if (this.import_options.selected_products.map(x => x.id).includes(product.id)) {
        this.import_options.selected_products.splice(this.import_options.selected_products.findIndex(x => x.id === product.id), 1);
      }
      // Otherwise, select the product
      else {
        this.import_options.selected_products.push(product);
      }
    },
    selectCollection(collection, selected) {
      // Set the collection to loading
      this.$set(collection, 'loading', true);
      this.$axios.get(`${process.env.VUE_APP_API_BASE_URL}/websites/${this.website.website_id}/collections/${collection.id}/products`)
        .then(result => {
          // Stop the collection from loading
          this.$delete(collection, 'loading');
          // Add all the products to the selected products array
          for (var product of result.data.products) {
            if (selected === 'all') {
              // Remove all collection products from the selected products array
              this.import_options.selected_products.splice(this.import_options.selected_products.findIndex(x => x.id === product.id), 1);
            } else {
              // Add all collection products not already selected
              if (!(this.import_options.selected_products.map(x => x.id).includes(product.id))) {
                this.import_options.selected_products.push(product);
              }
            }
          }
          // Add all the products to the collection products array
          this.$set(collection, 'products', result.data.products);
        })
        .catch(err => {
          console.log(err);
          this.$delete(collection, 'loading');
        });
    },
    searchShopify(search, load_more=false) {
      if (this.search_loading === false) {
        this.search_loading = true;
        if (load_more === false) {
          search.cursor = null;
        }
        this.$axios.post(`${process.env.VUE_APP_API_BASE_URL}/websites/${this.website.website_id}/search`, search)
          .then(result => {
            // If load more, concat the results
            if (load_more) {
              this.search_results = this.search_results.concat(result.data.results);
            }
            // Otherwise, set the results
            else {
              this.search_results = result.data.results;
            }
            // Set the search cursor to the result (cursor or null)
            this.search.cursor = result.data.cursor;
            this.search_loading = false;
          })
          .catch(err => {
            this.search_loading = false;
            console.log(err);
          });
      }
    },
    debounceSearchShopify: _debounce(function (search) {
      if (this.search_loading === false) {
        this.searchShopify(search);
      }
    }, 600)
  }
}
</script>