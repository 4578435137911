<template>
  <v-list-item>
    <v-list-item-icon class="my-auto mr-2">
      <v-tooltip bottom>
        <template v-slot:activator="{ on }">
          <v-icon v-on="on" class="ma-auto">mdi-file-outline</v-icon>
        </template>
        <span>{{ product.__typename }}</span>
      </v-tooltip>
    </v-list-item-icon>

    <v-list-item-content>
      <v-list-item-title>{{ product.title }}</v-list-item-title>

      <v-list-item-subtitle>Variants: {{ product.totalVariants }}</v-list-item-subtitle>
    </v-list-item-content>

    <v-list-item-action>
      <v-btn @click="$emit('selectProduct', product)" class="rounded-lg">{{ selected ? 'Selected' : 'Select' }}</v-btn>
    </v-list-item-action>
  </v-list-item>
</template>

<script>
export default {
  props: {
    product: Object,
    selected_products: Array,
  },
  computed: {
    selected() {
      return this.selected_products.map(x => x.id).includes(this.product.id);
    }
  }
}
</script>