<template>
  <v-card elevation=4 outlined class="rounded-lg">
    <v-card-text>
      <v-form v-model="valid" @input="$emit('update:changes_valid', valid)">
        <v-row>
          <v-col cols=12 style="padding: 0px 12px">
            <v-img
              :src="website.image_url"
              max-height="100px"
              max-width="auto"
              contain
            ></v-img>
          </v-col>

          <v-col cols=12 style="padding-bottom: 0">
            <v-text-field ref="name_input" label="Name" v-model="website.name" :rules="rules.name" :readonly="!access.update_access" outlined></v-text-field>
          </v-col>

          <v-col cols=12 style="padding: 0px 12px">
            <v-select ref="type_input" label="Type" v-model="website.type" :items="website_types" :rules="rules.type" readonly outlined></v-select>
          </v-col>

          <v-col cols=12 style="padding: 0px 12px">
            <v-text-field ref="url_input" label="URL" v-model="website.url" :placeholder="urlPlaceHolder" :rules="rules.url" outlined></v-text-field>
          </v-col>

          <v-col cols=12 style="padding: 0px 12px" v-if="!website.oauth_token">
            <v-text-field ref="api_user_input" label="API User Name" v-model="website.api_user" :rules="rules.user_name" outlined :type="show_api_details ? 'text' : 'password'" @click:append="show_api_details = !show_api_details" :append-icon="show_api_details ? 'mdi-eye' : 'mdi-eye-off'"></v-text-field>
          </v-col>

          <v-col cols=12 style="padding: 0px 12px" v-if="!website.oauth_token">
            <v-text-field ref="api_key_input" label="API Key" v-model="website.api_key" :rules="rules.api_key" outlined :type="show_api_details ? 'text' : 'password'" @click:append="show_api_details = !show_api_details" :append-icon="show_api_details ? 'mdi-eye' : 'mdi-eye-off'"></v-text-field>
          </v-col>

          <v-col cols=12 style="padding: 0px 12px" v-if="website.oauth_token">
            <v-text-field ref="api_key_input" label="API Key" v-model="website.oauth_token" :rules="rules.api_key" outlined disabled :type="show_api_details ? 'text' : 'password'" @click:append="show_api_details = !show_api_details" :append-icon="show_api_details ? 'mdi-eye' : 'mdi-eye-off'"></v-text-field>
          </v-col>
        </v-row>
      </v-form>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  data() {
    return {
      valid: true,
      show_api_details: false,
    }
  },
  computed: {
    urlPlaceHolder() {
      let sites = {
        B2BWave: "https://samplesite.b2bwave.com/api",
        Shopify: "https://samplesite.myshopify.com/admin/api",
        BigCommerce: "https://samplesite.bigcommerce.com/api",
        WooCommerce: "https://samplesite.woocommerce.com/api"
      };
      if (this.website.type) {
        return sites[this.website.type];
      } else {
        return "Select Site Type First";
      }
    }
  },
  props: {
    website: Object,
    website_types: Array,
    access: Object,
    rules: Object,
  }
}
</script>