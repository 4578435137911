<template>
  <v-list-item>
    <v-list-item-icon class="my-auto mr-2">
      <v-tooltip bottom>
        <template v-slot:activator="{ on }">
          <v-icon v-on="on" class="ma-auto">mdi-file-cabinet</v-icon>
        </template>
        <span>{{ collection.__typename }}</span>
      </v-tooltip>
    </v-list-item-icon>

    <v-list-item-content>
      <v-list-item-title>{{ collection.title }}</v-list-item-title>

      <v-list-item-subtitle>Products: {{ collection.productsCount }}</v-list-item-subtitle>
    </v-list-item-content>

    <v-list-item-action>
      <v-btn @click="$emit('selectCollection', collection, selected)" class="rounded-lg" :loading="collection.loading">{{ button_text }}</v-btn>
    </v-list-item-action>
  </v-list-item>
</template>

<script>
export default {
  props: {
    collection: Object,
    selected_products: Array,
  },
  computed: {
    selected() {
      if (this.collection.products !== undefined) {
        let selected_products = this.selected_products.map(x => x.id);
        let collection_products = this.collection.products.map(x => x.id);

        const product_selected = (collection_id) => selected_products.includes(collection_id);

        // If all products in the collection are selected
        if (collection_products.every(product_selected)) {
          return 'all';
        }
        // If some products in the collection are selected
        if (collection_products.some(product_selected)) {
          return 'some';
        }
      }
      // If none of the products in the collection are selected
      return 'none';
    },
    button_text() {
      switch (this.selected) {
        case 'all':
          return 'Selected';
        case 'some':
          return 'Selected*';
        default:
          return 'Select';
      }
    }
  }
}
</script>