<template>
<v-dialog v-model="show" max-width="700px" @click:outside="$emit('update:show', false)" @keydown.esc="$emit('update:show', false)">
  <v-card>
  <v-container grid-list-md text-xs-center>
    <v-container fluid>
      <v-row>
        <v-col cols="12" sm="6">
          <h1 style="padding-bottom: 20px;">Convert Site</h1>
        </v-col>
        <v-col cols="12" sm="6" align="right">
          <v-btn color="primary" :disabled="api_user === '' || api_key === ''" @click="convert()">
            Convert
          </v-btn>
          <v-btn text @click="$emit('update:show', false);">
            Cancel
          </v-btn>
        </v-col>
      </v-row>
    </v-container>
        <v-form ref="website_details">
          <v-row>
            <v-col cols=12>
              <v-text-field ref="api_user_input" label="API User Name" v-model="api_user" :rules="rules.user_name" outlined></v-text-field>
            </v-col>

            <v-col cols=12>
              <v-text-field ref="api_key_input" label="API Key" v-model="api_key" :rules="rules.api_key" outlined></v-text-field>
            </v-col>
          </v-row>
        </v-form>
  </v-container>
  </v-card>
</v-dialog>
</template>

<script>
var clone = require('clone');
import { websitesLogic } from "@/mixins/websitesMixin.js";
export default {
  mixins: [websitesLogic],
  data() {
    return {
      api_user: "",
      api_key: "",
      rules: {
        user_name: [v => !!v || 'API User is required'],
        api_key: [v => !!v || 'API Key is required'],
      }
    }
  },
  props: {
    show: Boolean,
    website: Object,
  },
  methods: {
    async convert() {
      let updated_website = clone(this.website);
      updated_website.api_user = this.api_user;
      updated_website.api_key = this.api_key;
      await this.convertFromOauthToBasic(updated_website);
      updated_website.oauth_token = null;
      this.$store.commit("setWebsite", updated_website);
      this.$emit('update:show', false);
    },
  },
}
</script>