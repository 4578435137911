<template>
  <v-window style="overflow: visible;">
    <v-btn-toggle v-model="current_tab" mandatory style="justify-content: space-evenly;" class="background py-4" active-class="current-tab">
      <v-row>
        <v-col v-for="(tab, i) in tabs" :key="i">
          <v-btn elevation=4 class="button-style rounded-lg" :value="tab">{{ tab.name }}</v-btn>
        </v-col>
      </v-row>
    </v-btn-toggle>
    <v-divider class="mt-1 pb-5" />

    <!-- Website Details Card -->
    <div v-if="current_tab.name === 'Website Details'">
      <v-row v-if="Object.keys(website).length === 0" dense>
        <v-col cols="12">
          <v-skeleton-loader
            type="card-heading, list-item-three-line, image"
            elevation=2
            style="margin-bottom: 12px;"
          ></v-skeleton-loader>
          <v-skeleton-loader
            type="card-heading, list-item-three-line, list-item-three-line"
            elevation=2
          ></v-skeleton-loader>
        </v-col>
      </v-row>
      <v-row v-else>
        <v-col cols="12">
          <!-- Main Website Information -->
          <SiteDetails
          :website.sync="website"
          :website_types="website_types"
          :access="access"
          :rules="rules"
          v-on="$listeners"
          ></SiteDetails>
        </v-col>
      </v-row>
    </div>

    <!-- Website Settings Card -->
    <div v-if="current_tab.name === 'Website Settings'">
      <v-row v-if="Object.keys(website).length === 0" dense>
        <v-col cols="12">
          <v-skeleton-loader
            type="card-heading, list-item-three-line, image"
            elevation=2
            style="margin-bottom: 12px;"
          ></v-skeleton-loader>
          <v-skeleton-loader
            type="card-heading, list-item-three-line, list-item-three-line"
            elevation=2
          ></v-skeleton-loader>
        </v-col>
      </v-row>
      <v-row v-else>
        <v-col cols="12">
          <!-- Website Settings Information -->
          <SiteSettings
          :website.sync="website"
          :priceLevels="priceLevels"
          :access="access"
          :rules="rules"
          v-on="$listeners"
          ></SiteSettings>
        </v-col>
      </v-row>
    </div>

    <!-- Audit Logs Card -->
    <div v-if="current_tab.name === 'Audit Logs'">
      <AuditLog
        :logs="auditLogs"
      ></AuditLog>
    </div>
  </v-window>
</template>

<script>
import { auditLogsLogic } from '@/mixins/auditLogsMixin.js'
import priceLevelsMixin from '@/mixins/priceLevelsMixin.js';
import SiteDetails from "@/components/site/SiteDetails.vue"
import SiteSettings from "@/components/site/SiteSettings.vue"
import AuditLog from "@/components/auditLogs/AuditLog.vue"
export default {
  mixins: [auditLogsLogic, priceLevelsMixin],
  data() {
    return {
      current_tab: { name: 'Website Details' },
      tabs: [
        { name: 'Website Details', initialized: false },
        { name: 'Website Settings', initialized: false },
        { name: 'Audit Logs', initialized: false },
      ],
      auditLogs: [],
    }
  },
  props: {
    website: Object,
    website_types: Array,
    access: Object,
    rules: Object,
  },
  components: {
    SiteDetails,
    SiteSettings,
    AuditLog,
  },
  watch: {
    async current_tab(tab) {
      if (tab.name === 'Audit Logs') {
        this.auditLogs = await this.getAuditLogs(this.$route.params.id, this.$route.path.split('/')[1]);
      } else if (tab.name === 'Website Settings' && !tab.initialized) {
        this.priceLevels = await this.getPriceLevels();
        if (this.website.type === 'B2BWave') {
          await this.$store.dispatch("getWebsitePriceLists", this.website.website_id);
        }
      }
      tab.initialized = true;
    }
  }
}
</script>