<template>
  <v-card elevation=3 outlined class="rounded-lg">
    <v-card-text>
      <v-form v-model="valid" @input="$emit('update:changes_valid', valid)">
        <v-row>
          <v-col cols=auto class="mx-auto" v-for="value, setting in boolean_settings" :key="setting">
            <v-checkbox v-model="website.settings[setting]" :label="`${setting_names[setting]}`"></v-checkbox>
          </v-col>
        </v-row>
        <!-- Shopify specific settings -->
        <div v-if="website.type === 'Shopify'">
          <v-row>
            <v-col>
              <v-select
                v-model="website.settings.sell_at_level_id"
                :items="priceLevels"
                label="Sale Price"
                placeholder="Price level used in Price section"
                persistent-placeholder 
                item-text="name"
                item-value="priceLevel_id"
                class="rounded-lg"
                outlined
                clearable
              >
              </v-select>
            </v-col>
            <v-col>
              <v-select
                v-model="website.settings.compare_at_level_id"
                :items="priceLevels"
                label="RRP Price"
                placeholder="Price level used in Compare at price section"
                persistent-placeholder 
                item-text="name"
                item-value="priceLevel_id"
                class="rounded-lg"
                outlined
                counter
                clearable
              >
                <template v-slot:counter>
                  <div style="color: grey">
                    <b>Note</b>: If left empty, pricing will not be pushed on product publish.
                  </div>
                </template>
              </v-select>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-text-field v-model="website.settings.meta_title_format" label="Meta Title Format" placeholder="Prefix | {title} | Suffix" persistent-placeholder :rules="meta_title_rules" counter outlined clearable class="rounded-lg">
                <template v-slot:counter>
                  <div style="color: grey">
                    <b>Note</b>: If left empty, meta title will be displayed using the default format.
                  </div>
                </template>
              </v-text-field>
            </v-col>
          </v-row>
        </div>
        <!-- B2BWave specific settings -->
        <div v-if="website.type === 'B2BWave'">
          <v-row>
            <v-col>
              <v-select
                v-model="website.settings.rrp_price_id"
                :items="priceLevels"
                label="RRP Price"
                placeholder="Price level used in RRP section"
                persistent-placeholder 
                item-text="name"
                item-value="priceLevel_id"
                class="rounded-lg"
                outlined
                counter
                clearable
              >
                <template v-slot:counter>
                  <div style="color: grey">
                    <b>Note</b>: If left empty, RPP will not be pushed on product publish.
                  </div>
                </template>
              </v-select>
            </v-col>
          </v-row>
          <v-row justify="center">
            <v-col cols="5.5">
              <v-card outlined class="rounded-lg">
                <v-card-title class="py-2 justify-center">ItemFlo Price Levels</v-card-title>
                <v-divider />
                <v-card-text style="height:200px; overflow: auto;">
                  <draggable :value="priceLevels" v-bind="dragOptions" :group="{ name: 'pricelists', pull: 'clone', put: false }" :move="(event) => highlightTarget(event)">
                    <transition-group>
                      <v-card outlined class="dragable-card rounded-lg" v-for="(priceLevel, idx) in priceLevels" :key="priceLevel.priceLevel_id" :class="idx === priceLevels.length-1 ? '' : 'mb-2'">
                        <v-card-title class="py-2 justify-center">
                          <h4>{{ priceLevel.name }}</h4>
                        </v-card-title>
                      </v-card>
                    </transition-group>
                  </draggable>
                </v-card-text>
              </v-card>
            </v-col>

            <v-icon>mdi-chevron-double-right</v-icon>

            <v-col cols="5.5">
              <v-card outlined class="rounded-lg">
                <v-card-title class="py-2 justify-center">Website Price Lists</v-card-title>
                <v-divider />
                <v-card-text style="height:200px; overflow: auto;">
                  <div v-for="(pricelist, idx) in website.pricelists" :key="pricelist.pricelist_id">
                    <draggable @add="onAdd(pricelist.pricelist_id)" v-model="mapped_pricelists" :id="`pricelist_${pricelist.pricelist_id}`" :disabled="!dragging" ghost-class="destination-item" group="pricelists">
                      <div @dragenter.stop.prevent="onDragEnter" @dragleave.stop.prevent="onDragLeave">
                      <v-card outlined class="rounded-lg" :class="{ highlight: dragging && targeted_pricelist === `pricelist_${pricelist.pricelist_id}`, 'mb-2': idx !== website.pricelists.length-1 }">
                        <v-card-title class="py-2 justify-center">
                          <h4>{{ pricelist.name }}</h4>
                          <v-chip small outlined class="ml-2">{{ getLinkStatus(pricelist) }}</v-chip>

                          <div style="position: absolute; right: 5px; top: 6px;" v-if="access.delete_access">
                            <v-tooltip bottom>
                              <template v-slot:activator="{ on }">
                                <div v-on="on">
                                  <v-btn :disabled="pricelist.priceLevel_id === null" small icon @click="pricelist.priceLevel_id = null"><v-icon>mdi-link-variant-remove</v-icon></v-btn>
                                </div>
                              </template>
                              <span v-if="pricelist.priceLevel_id !== null">Unlink Price Level</span>
                              <span v-if="pricelist.priceLevel_id === null">Price Level Not Linked</span>
                            </v-tooltip>
                          </div>
                        </v-card-title>
                      </v-card>
                      </div>
                    </draggable>
                  </div>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </div>
      </v-form>
    </v-card-text>
  </v-card>
</template>

<script>
import draggable from "vuedraggable";
export default {
  components: {
    draggable,
  },
  data() {
    return {
      dragging: false,
      targeted_pricelist: null,
      counter: 0,
      mapped_pricelists: [],
      valid: true,
      meta_title_rules: [v => ((v === null || v === "")) || v.includes("{title}") || "Meta Title must include a {title} tag"],
      setting_names: {
        'track_quantity': 'Track Quantity',
        'allow_backorder': 'Allow Backorders',
        'track_dimensions': 'Track Dimensions',
        'track_weight': 'Track Weight',
        'overwrite_discounts': 'Overwrite Discounts',
      },
      dragOptions: {
        animation: 200,
        disabled: !this.access.update_access,
        ghostClass: "ghost",
      }
    }
  },
  props: {
    website: Object,
    priceLevels: Array,
    access: Object,
  },
  computed: {
    boolean_settings() {
      // eslint-disable-next-line
      return Object.fromEntries(Object.entries(this.website.settings).filter(([key, val]) => typeof(val) === 'boolean'));
    }
  },
  methods: {
    highlightTarget(event) {
      this.targeted_pricelist = event.to.id;
    },
    onDragEnter() {
      this.counter++;
    },
    onDragLeave() {
      if (this.counter > 0) {
        this.dragging = true;
        this.counter--;
      }
      if (this.counter === 0) {
       this.dragging = false;
       this.targeted_pricelist = null;
      }
    },
    onAdd(pricelist_id) {
      if (this.dragging) {
        // Add the ItemFlo price level ID to the selected website pricelist object
        let pricelist = this.website.pricelists[this.website.pricelists.findIndex(x => x.pricelist_id === pricelist_id)]
        pricelist.priceLevel_id = this.mapped_pricelists[0].priceLevel_id;
      }

      // Reset to initial state
      this.dragging = false;
      this.counter = 0;
      this.targeted_pricelist = null;
      this.mapped_pricelists.pop();
    },
    getLinkStatus(pricelist) {
      let priceLevel = this.priceLevels[this.priceLevels.findIndex(x => x.priceLevel_id === pricelist.priceLevel_id)]
      if (priceLevel) {
        return `Linked to: ${priceLevel.name}`;
      } else {
        return 'Unlinked';
      }
    }
  }
}
</script>

<style scoped>
.destination-item {
  display: none;
}
.dragable-card:hover {
  cursor: grab;
}
.highlight {
  border: 2px solid #1976D2;
}
</style>