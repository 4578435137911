<template>
  <v-container fluid>
    <Header
      title="Edit Site"
      :subtitle="`Last synced: ${('last_synced' in website && website.last_synced !== null) ? new Date(`${website.last_synced.replaceAll('-', '/')} UTC`).toLocaleString() : 'never'}`"
      :divider="false"
      :options="header_options"
    ></Header>

    <!-- Unsaved Changes Bar -->
    <UnsavedChanges
      text="Site Has Unsaved Changes"
      :changes="changes"
      :changes_valid="changes_valid"
      :saveChanges="saveChanges"
      :discardChanges="discardChanges"
    ></UnsavedChanges>

    <ActionDialog ref="delete_dialog" title="Deleting Website" color="error">
      <template v-slot>
        <span class="error--text" v-html="'Are you sure you want to delete this website.<br>Warning! This will remove all links related to this website.'"></span>
      </template>
    </ActionDialog>

    <ActionDialog ref="sync_dialog" title="Sync Website" color="success">
      <template v-slot="{ options }">
        <v-select
          v-model="options.data.settings"
          :items="sync_site_options"
          :rules="[v => v.length >= 1 || 'You must select at least one type']"
          label="Sync Settings"
          outlined
          multiple
          hide-details=auto
          class="rounded-lg"
        ></v-select>
      </template>
    </ActionDialog>

    <!-- Website Data Tabs -->
    <SiteTabs
    :website="website"
    :website_types="website_types"
    :access="access"
    :changes_valid.sync="changes_valid"
    :rules="rules"
    ></SiteTabs>

    <!-- Covert To Basic Auth Modal -->
    <ConvertSiteToBasicAuth
    :show.sync="convertSite"
    :website.sync="website"
    ></ConvertSiteToBasicAuth>

    <ImportWebsiteProductDialog
      ref="import_dialog"
      :website="website"
    ></ImportWebsiteProductDialog>
  </v-container>
</template>

<script>
import { websiteCore, websitesLogic } from "@/mixins/websitesMixin.js";
import Header from "@/components/Header.vue"
import UnsavedChanges from "@/components/UnsavedChanges.vue"
import ActionDialog from "@/components/ActionDialog.vue"
import SiteTabs from "@/components/site/SiteTabs.vue"
import ConvertSiteToBasicAuth from "@/components/site/ConvertSiteToBasicAuth.vue"
import ImportWebsiteProductDialog from "@/components/import-export/website-products/ImportWebsiteProductDialog.vue"
export default {
  mixins: [websiteCore, websitesLogic],
  data() {
    return {
      website_types: ["B2BWave", "Shopify"],
      changes_valid: true,
      access: this.$store.getters.getAccessLevel['websites'],
      rules: {
        name: [v => !!v || 'Name is required'],
        type: [v => !!v || 'Type is required'],
        url: [v => !!v || 'URL is required'],
        user_name: [v => !!v || 'API User is required'],
        api_key: [v => !!v || 'API Key is required'],
      },
      convertSite: false,
      sync_data: {
        settings: [],
      },
    }
  },
  components: {
    Header,
    UnsavedChanges,
    ActionDialog,
    SiteTabs,
    ConvertSiteToBasicAuth,
    ImportWebsiteProductDialog,
  },
  watch: {
    website() {
      this.sync_data.settings = this.getSyncDefaults();
    }
  },
  methods: {
    getSyncDefaults() {
      switch (this.website.type) { 
        case 'Shopify':
          return ['Products', 'Tags'];
        case 'B2BWave':
          return ['Products', 'Brands', 'Categories'];
        default:
          return [];
      }
    },
    onSyncWebsite() {
      this.$refs.sync_dialog.showDialog(this.sync_data)
        .then(async data => {
          await this.syncWebsite({ website_id: this.website.website_id, website_name: this.website.name, settings: data.settings });
          this.$store.dispatch("getWebsite", this.$route.params.id);
        })
        .catch(() => {
          this.sync_data.settings = this.getSyncDefaults();
        });
    },
    async getUpdatesRequired() {
      // Initialize values for getting required updates
      let old_website = this.$store.state.websites.oldData.website;
      let updates = {};

      for (var key in this.website) {
        // Switch on the key to determine how to process updates required
        switch (key) {
          case 'settings':
            // Check for settings changes
            for (var skey in this.website[key]) {
              // Check for changes to the website settings
              if (this.website[key][skey] !== old_website[key][skey]) {
                // If website object not created, initialize
                if (!("settings" in updates)) {
                  updates["settings"] = { website_settings_id: this.website[key].website_settings_id };
                }
                updates["settings"][skey] = this.website[key][skey];
              }
            }
            break;
          case 'pricelists':
            var mappings = [];
            // Iterate through each pricelist
            for (var i in this.website['pricelists']) {
              // Check for pricelist changes
              if (this.website[key][i].priceLevel_id !== old_website[key][i].priceLevel_id) {
                mappings.push({ pricelist_id: this.website[key][i].pricelist_id, priceLevel_id: this.website[key][i].priceLevel_id })
              }
            }
            // If website object not created, initialize
            if (mappings.length > 0) {
              updates["pricelists"] = { mappings: mappings };
            }
            break;
          default:
            // Check for changes to the core website
            if (this.website[key] !== old_website[key]) {
              // If website object not created, initialize
              if (!("website" in updates)) {
                updates["website"] = { website_id: this.website.website_id };
              }
              updates["website"][key] = this.website[key];
            }
        }
      }

      return updates;
    },
    async saveChanges() {
      this.$store.dispatch("performingUpdate", true);
      let updates = await this.getUpdatesRequired();

      // Update the website details if required
      if ("website" in updates) {
        await this.updateWebsite(updates["website"]);
      }
      // Update the website settings if required
      if ("settings" in updates) {
        await this.updateWebsiteSettings({ payload: updates["settings"], website_type: this.website.type });
      }
      // Update the website pricelists if required
      if ("pricelists" in updates) {
        await this.updateWebsitePriceListMappings(updates["pricelists"]);
      }
      this.$store.dispatch("performingUpdate", false);
    }
  },
  computed: {
    sync_site_options() {
      switch (this.website.type) { 
        case 'Shopify':
          return ['Products', 'Tags']
        case 'B2BWave':
          return ['Products', 'Brands', 'Categories', 'Price Lists']
        default:
          return [];
      }
    },
    header_options() {
      let options = [
        {
          'title': 'Delete',
          'icon': 'mdi-delete',
          'disabled': !this.$store.getters.getAccessLevel['websites'].delete_access,
          'action': () => {this.$refs.delete_dialog.showDialog({ website_id: this.website.website_id, website_name: this.website.name }).then(data => this.deleteWebsite(data)).catch(() => {})}
        },
        {
          'title': 'Sync',
          'icon': 'mdi-autorenew',
          'disabled': !this.$store.getters.getAccessLevel['websites'].update_access,
          'action': () => {this.onSyncWebsite()}
        }
      ];
      if (this.website.type === 'Shopify' && this.website.oauth_token) {
        options.unshift(
          {
            'title': 'Convert To Basic Auth',
            'icon': 'mdi-key-chain',
            'disabled': !this.$store.getters.getAccessLevel['websites'].update_access,
            'action': () => {this.convertSite = true}
          }
        );
      }
      if (this.website.type === 'Shopify' || this.website.type === 'B2BWave') {
        options.unshift(
          {
            'title': 'Import Products',
            'icon': 'mdi-download',
            'action': () => this.$refs.import_dialog.show()
          }
        );
      }
      return options;
    }
  }
}
</script>