import { render, staticRenderFns } from "./SiteSettings.vue?vue&type=template&id=545dce4c&scoped=true&"
import script from "./SiteSettings.vue?vue&type=script&lang=js&"
export * from "./SiteSettings.vue?vue&type=script&lang=js&"
import style0 from "./SiteSettings.vue?vue&type=style&index=0&id=545dce4c&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "545dce4c",
  null
  
)

export default component.exports